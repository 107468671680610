import React from "react";

const FooterSection = () => {
  return (
    <>
      <div className="footer-container container" id="about-us">
        <div className="footer-section footer-links" style={{ width: "40%" }}>
          <ul>
            <li>6/7 Dhwani Industrial Area</li>
            <li>Near Solvent Fatak</li>
            <li>Meera papad Street</li>
            <li>Rajkot -360022</li>
            <li>
              <a href="mailto:info@anvitcomponents.in">
                <i className="fa fa-envelope"></i>&nbsp;info@anvitcomponents.in
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section footer-links" style={{ width: "42%" }}>
          <ul>
            <li>
              <a className="nav-link" href="https://wa.me/917984692054">
                <i className="fa fa-whatsapp" aria-hidden="true"></i> Mr. Vivek V. Sanghvi
              </a>
            </li>
            <li>
              <a className="nav-link" href="https://wa.me/919825708467">
                <i className="fa fa-whatsapp" aria-hidden="true"></i> Mr. Anil H. Hadwani
              </a>
            </li>
            <li>
              <a className="nav-link" href="https://wa.me/919898665620">
                <i className="fa fa-whatsapp" aria-hidden="true"></i> Mr. Sagar Rajani
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-section footer-links" style={{ width: "18%" }}>
          <ul>
            <li>
              <a className="nav-link" href="#home">
                Home
              </a>
            </li>
            <li>
              <a className="nav-link" href="#work-collections">
                Collections
              </a>
            </li>
            <li>
              <a className="nav-link" href="#about">
                About
              </a>
            </li>
          </ul>
          <div className="social-links">
            <a
              href="https://www.facebook.com/share/WNiZSZV67xZx7Ryv/?mibextid=qi2Omg"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-facebook-f"></i>
            </a>

            <a
              href="https://www.instagram.com/anvitcomponents?utm_source=qr&igsh=MXAwMHRpcWQ2bDNzdw=="
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
      <p className="copyright">&copy; 2025 Powered by Anvit Components</p>
    </>
  );
};

export default FooterSection;
